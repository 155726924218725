
import { Options, Vue } from 'vue-class-component';
import RulesModal from '../modals/rulesModal.vue';
import store from '@/store';

@Options({
    components: {}
})
export default class Landing extends Vue {

    created() {
        if(store.state.loggedUser.identifier)
            this.$router.push('/profile');
    }
    
    openRulesModal() {
        this.$opModal.show(RulesModal, {})
    }

}
